@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      padding: 20px;
      max-width: 280px;
      border-style: solid;
      border-width: thin;
    }

    .AlignRight {
        text-align: right
    }
  }